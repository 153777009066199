import React, { useReducer, createContext, useEffect } from "react"

export const CartContext = createContext({})

export default function CartProvider({ children }) {
  const initialState = []
  const [cart, dispatch] = useReducer(cartReducer, initialState)
  return <CartContext.Provider value={{ cart, dispatch }}>{children}</CartContext.Provider>
}

export const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return [
        ...state,
        {
          title: action.title,
          price: action.price,
          id: action.id,
          image: action.image,
          variantId: action.variantId,
          variantName: action.variantName,
          quantity: action.quantity,
        },
      ]
    case "UPDATE_QUANTITY":
      return state.map(item => {
        if (item.variantId === action.variantId) {
          return { ...item, quantity: action.quantity }
        }
        return item
      })
    case "INCREASE_QUANTITY":
      return state.map(item => {
        if (item.variantId === action.variantId) {
          return { ...item, quantity: item.quantity + 1 }
        }
        return item
      })
    case "DELETE_ITEM":
      return state.filter(item => {
        return item.variantId !== action.variantId
      })
    default:
      return state
  }
}
