

const React = require("react")
const Mobile = require("./src/components/Context/mobile").default
const CartContext = require("./src/components/Context/cartProvider").default
const Cart = require("./src/components/components/cart").default

const LoadingContextController = require("./src/components/Context/Loading").default

const MenuContext = require("./src/components/Context/menu").default

export const wrapRootElement = ({ element, loc }) => (
  <LoadingContextController>
    <MenuContext>
      <Mobile>
        <CartContext>{element}</CartContext>
      </Mobile>
    </MenuContext>
  </LoadingContextController>
)
