import React , {createContext,useReducer} from 'react'

export const LoadingContext = createContext()

const LoadingContextController = ({children}) =>{

    const [loading,setLoading]  = useReducer(reducer, {linesLoaded:false,backgroundLoaded:false,menuLoaded:false,loadedFinish :false})

    //in order 
    function reducer (state,action){
        switch(action.type){
            case "loadLines":
                return{ ...state, linesLoaded:action.payload.loadLines}  
            case 'loadBackground':
                return{ ...state, backgroundLoaded:true}    
            case 'loadMenu':
                return{ ...state, backgroundLoaded:true}
            case 'finishLoad':
                return { ...state, loadedFinish:true}   
            default:
                return;     
        }
    }
    return (
        <LoadingContext.Provider value={{loading,setLoading}}>
            {children}
        </LoadingContext.Provider>
    )
}

export default LoadingContextController

