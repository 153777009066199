import React, { useContext, useEffect, useState } from "react"
import Client from "shopify-buy"
import fetch from "isomorphic-fetch"
import { CartContext } from "../Context/cartProvider"
import { GatsbyImage } from "gatsby-plugin-image"
function Cart() {
  const { cart } = useContext(CartContext)
  const shopifyStorefrontAccessToken = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
  const shopifyDomain = process.env.GATSBY_SHOPIFY_STORE_URL
  const client = Client.buildClient(
    {
      domain: shopifyDomain,
      storefrontAccessToken: shopifyStorefrontAccessToken,
    },
    fetch
  )

  function handleCartClose() {
    const cartDiv = document.querySelector(".cart")
    const overlay = document.querySelector(".overlay")
    cartDiv.classList.add("closed")
    overlay.classList.add("hidden")
  }

  function handleCheckout() {
    var newWindow = window.open()
    client.checkout.create().then(checkout => {
      const lineItemsToAdd = cart.map(item => {
        return { variantId: item.variantId, quantity: item.quantity }
      })
      client.checkout.addLineItems(checkout.id, lineItemsToAdd).then(checkout => {
        const checkoutUrl = checkout.webUrl
        newWindow.location = checkoutUrl
      })
    })
  }

  return (
    <>
      <div className='overlay hidden' onClick={() => handleCartClose()} />
      <div className='cart closed'>
        <div className='cart-header'>
          <h3>Cart</h3>
          <button onClick={() => handleCartClose()}>
            <div className='x-icon' />
          </button>
        </div>
        <div className='cart-items-wrapper'>
          {cart.map(item => (
            <div key={item.variantId} className='cart-item'>
              <div className='product-info'>
                <p>${item.price * item.quantity}</p>
                <h4>{item.title}</h4>
                {item.variantName && <p>{item.variantName}</p>}
                <UpdateQuantity variantId={item.variantId} quantity={item.quantity} handleCartClose={handleCartClose} />
              </div>
              <div className='product-image'>
                <GatsbyImage image={item.image} className='product-img' alt={item.title + " " + item.variantName} />
              </div>
            </div>
          ))}
        </div>
        <button name='Buy Now' className='w-100p black-hover enquire-button flex al-c-i buy-now checkout-btn' onClick={() => handleCheckout()}>
          CHECKOUT
        </button>
      </div>
    </>
  )
}

function UpdateQuantity({ variantId, quantity, handleCartClose }) {
  const { dispatch } = useContext(CartContext)
  const [noOfItems, setNoOfItems] = useState(quantity)
  function handleRemove() {
    dispatch({ type: "DELETE_ITEM", variantId })
  }

  useEffect(() => {
    dispatch({ type: "UPDATE_QUANTITY", variantId: variantId, quantity: noOfItems })
  }, [variantId, noOfItems])

  return (
    <div className='update-quantity'>
      <button disabled={noOfItems === 1} onClick={() => setNoOfItems(prev => prev - 1)}>
        -
      </button>
      {quantity}
      <button onClick={() => setNoOfItems(prev => prev + 1)}>+</button>
      <button className='removeBtn' onClick={handleRemove}>
        Remove
      </button>
    </div>
  )
}

export default Cart
