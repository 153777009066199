import React, { createContext, useEffect, useState } from 'react'

export const MobileContext = createContext()


const Mobile = ({ children }) => {
    useEffect(() =>{
        checkMobile()
    },[])
    const [mobile, setMobile] = useState(true)

    const checkMobile = () => {
        if ((window.innerWidth < 991 ) || (window.innerHeight < 550)) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            checkMobile()
        })
        return () => {
            window.removeEventListener('resize', () => {
                checkMobile()
            })
        }
    }, [])



    return (
        <MobileContext.Provider value={{mobile}}>
            {children}
        </MobileContext.Provider>
    )

}

export default Mobile