import React, { useReducer, createContext } from "react"

export const MenuContextController = createContext()

const MenuContext = ({ children }) => {
    const [menu, setMenu] = useReducer(reducer, { menuOpen: false, secondaryMenuOpen: false, currentLink: null, bodyFade: false, buttonActive: false, load: false, formSubmitted: false, enableContact: false, currentPage: undefined })
    function reducer(state, action) {
        switch (action.type) {
            case 'openMenu':
               
                return { ...state, menuOpen: action.payload.menu, secondaryMenuOpen: false };
            case 'closeMenu':
               
                return { ...state, menuOpen: action.payload.menu, secondaryMenuOpen: action.payload.secondary }
            case 'openSecondary':
               
                return { ...state, menuOpen: action.payload.menu, secondaryMenuOpen: !state.secondaryMenuOpen }
            case 'buttonPressed':

                return { ...state, bodyFade: action.payload.bodyFade, secondaryMenuOpen: false }
            case 'buttonActive':

                return { ...state, buttonActive: action.payload.buttonActive }
            case 'closeSecond':
             
                return { ...state, secondaryMenuOpen: false }
            case 'loadMenu':
                return { ...state, load: true }

            case 'formSubmitted':
                return { ...state, formSubmitted: true }
            case 'closeForm':
                return { ...state, formSubmitted: false }
            case 'enableContact':
                return { ...state, enableContact: !state.enableContact }
            case 'setCurrentPage':
                return { ...state, currentPage: action.payload.currentPage }
            default:
                return;


        }
    }
    return (
        <MenuContextController.Provider value={{ menu, setMenu }}>
            {children}
        </MenuContextController.Provider>
    )
}

export default MenuContext



